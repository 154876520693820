<template>
	<div class="content">
		<Form :model="search" :label-width="120">
			<Row>
				<Col span="8">
				<FormItem label="用户昵称">
					<Input v-model="search.nickName" placeholder="请输入用户昵称"></Input>
				</FormItem>
				</Col>
				<Col span="8">
				<FormItem label="用户电话">
					<Input v-model="search.phone" placeholder="请输入用户电话"></Input>
				</FormItem>
				</Col>


				<Col span="8" style="text-align: right;">
				<FormItem>
					<Button type="primary" class="margin_left_10" @click.native="getWeChatUserAmountPage">查询</Button>
				</FormItem>


				</Col>
			</Row>
		</Form>
		<Table :columns="columns" :data="data">

			<template slot-scope="{ row }" slot="action">

				<Button size="small" type="error" @click.native="deductMoney(row)" class="margin_right_10">扣款</Button>
			</template>
		</Table>
		<Page class="margin_10" v-if="page.pages > 1" :total="page.total" :current="page.pageNum"
			@on-change="changePage" show-elevator show-sizer />
		<!-- 扣款 -->
		<Modal title="扣款" v-model="show" class-name="vertical-center-modal" @on-cancel="cancel" :loading="loading"
			:mask-closable="false" :footer-hide="true">
			<Form :model="formItem" :label-width="80" :rules="ruleFormItem" ref="form">

				<FormItem label="账户余额">
					{{userAmount||0}}
				</FormItem>
				<FormItem label="扣款金额" prop="amount">
					<Input v-model="formItem.amount" type="number" placeholder="请输入扣款金额"></Input>
				</FormItem>

				<FormItem style="text-align: right;">
					<Button type="primary" @click="cancel">取消</Button>
					<Button @click="save" style="margin-left: 8px">提交</Button>
				</FormItem>

			</Form>
		</Modal>
	</div>
</template>
<script>
	import {
		weChatUserAmountPage,
		weChatUserAmountDeductMoney
	} from '@/api/merchant/merchant.js'
	export default {
		name: 'merchantOrder',

		data() {
			
			return {
				show: false,
				loading: false,
				search: {
					merchantAppid: JSON.parse(sessionStorage.getItem('merchantInfo')).appid,
					nickName: '',
					phone: ''
				},

				page: {
					pageNum: 1,
					pageSize: 10,
					total: 0,
					pages: 1
				},
				columns: [{
						title: '用户名',
						key: 'nickName'
					},
					{
						title: '电话号码',
						key: 'phone'
					},
					{
						title: '账户余额',
						key: 'amount',
						render: (h, params) => {
							console.log()
							return h('div', params.row.amount || 0);
						}
					},


					{
						title: '创建时间',
						key: 'payTime',
						render: (h, params) => {
							if (params.row.updatedAt) {
								return h('div', this.$dayjs(params.row.updatedAt).format('YYYY-MM-DD HH:mm:ss'));
							} else {
								return h('div', '');
							}

						}
					},
					{
						title: '操作',
						slot: 'action'
					}
				],
				data: [],
				// 
				userAmount: 0,
				formItem: {
					amount: '', //金额
				},
				ruleFormItem: {
					amount: [{
						required: true,
						message: '金额不得为空',
						trigger: 'blur'
					}],

				},
			}
		},
		created() {

			this.getWeChatUserAmountPage()
		},
		methods: {

			getWeChatUserAmountPage() {
				let page = {
					pageNum: this.page.pageNum - 1,
					pageSize: this.page.pageSize
				}
				let _search = Object.assign({}, this.search, page);

				_search.payTimeStart = _search.payTimeStart ? new Date(_search.payTimeStart).getTime() : ''
				_search.payTimeEnd = _search.payTimeEnd ? new Date(_search.payTimeEnd).getTime() : ''
				weChatUserAmountPage(_search).then(res => {
					console.log(32, res)
					this.data = res.data.data.data

					this.page = {
						total: res.data.data.total,
						pageSize: res.data.data.pageSize,
						pageNum: res.data.data.pageNum,
						pages: res.data.data.pages
					}
				}).catch(reason => {
					console.log(reason);
				})
			},
			changePage(pageNum) {
				this.page.pageNum = pageNum;
				this.getWeChatUserAmountPage();
			},
			// 扣款
			deductMoney(item) {
				this.show = true
				this.userAmount = item.amount
				this.formItem.weChatMiniProgramUserUuid = item.uuid
			},
			save() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						if(this.formItem.amount>this.userAmount){
							this.$Message.warning('用户余额不足以扣除当前金额！')
							return false
						}
						this.loading = true
						this.$confirm('此操作将扣除用户余额, 是否继续?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							weChatUserAmountDeductMoney(this.formItem).then(res => {
								this.formItem = {
									amount: '', //金额
								}
								this.loading = false
								this.show = false
								this.getWeChatUserAmountPage()
							})

						}).catch(() => {
							this.$message({
								type: 'info',
								message: '已取消扣款操作'
							});
						});
					}
				})


			},
			cancel() {
				this.show = false
				this.loading = false
				this.userAmount = 0
				this.formItem = {
					amount: '', //金额
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.content {

		.contentLeft {
			width: 350px;
			height: 100%;
			display: flex;
			flex-direction: column;
			overflow: hidden;
		}

		.contentRight {
			flex: 1;
		}
	}
</style>